.main-wrapper {
    width: 150px;
    display: flex !important;
    align-items: center !important;
    // flex-direction: row !important;
    // justify-content: space-between !important;
}

.refresh-backdrop {
    z-index: 100 !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.refresh-progress-container {
    // text-align: center !important;
}

.refresh-progress {
    color: #8a85ff !important;
    margin-right: 10px !important;
}

.refresh-token-text {
    color: white;
    font-size: 15px;
}

.main-app-body {
    overflow-x: hidden;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    min-width: 100%;
}

@media only screen and (max-height: 450px) {
    .main-app-body {
        height: 100% !important;
    }
}
