.useractivity-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.useractivity-inner-wrapper {
    min-width: 850px;
    max-width: 850px;
}

.useractivity-title-progress-con {
    display: flex;
    align-items: center;
}

.useractivity-progress {
    margin-left: 10px;
}
