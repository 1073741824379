html {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  min-height: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #22252c !important;
}

::-webkit-scrollbar-thumb {
  background-color: #393f49;
  border-radius: 3px;
  box-shadow: outset 0 0 6px rgba(0, 0, 0, 0.3);
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}