.activitylogs-main-paper {
    padding: 0;
}

.activitylog-not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 420px);
}

.feed-markdown{
    width: 100%;
}