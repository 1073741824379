.main-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
    justify-content: center;
}

.app-logo {
    height: 80px;
    width: auto;
}

.input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input_fields_div {
    width: 49%;
}

.full-width {
    width: 100%;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.link {
    text-decoration: none;
    color: inherit;
    :hover {
        opacity: 1;
        transition: 0.2s;
        color: #8a85ff;
    }
}

.list-item {
    display: flex;
    align-items: center;
}

.avatar {
    margin-right: 10px;
    height: 20px !important;
    width: 20px !important;
}

.submit-progress {
    margin-right: 10px;
}

.auth-signup-paper {
    padding: 20px;
    min-height: 400px;
    max-height: 600px;
}

.auth-signup-logo-img {
    width: 150px;
    height: auto;
}