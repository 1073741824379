// GET LICENSE COMPONENT
.get-license {
    display: flex;
    align-items: center;
    justify-content: center;
}

.getlicense-inner-section {
    min-width: 850px;
    max-width: 850px;
}

.getlicense-inner-wrapper {
    display: flex;
    overflow: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 240px);
    max-height: calc(100vh - 240px);
}

.site-container {
    width: 70%;
}

.site-container-inner {
    display: flex;
    align-items: cente;
    flex-direction: column;
    justify-content: center;
}

.site-link-container {
    text-align: center !important;
}

.site-logo-link {
    width: 100%;
    height: auto;
    text-align: center;
}

.social-network-container {
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.social-network-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.social-network-img {
    width: auto;
    height: 100px;
}

.social-network-link {
    color: white;
    text-decoration: none;
}
// GET LICENSE COMPONENT ENDS

// Copyright Component

.copy-right-container {
    text-align: center;
}

// Navigation Menu Component

.main-navigation-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-paper {
    margin: 0;
    width: 100%;
    display: flex;
    padding: 0 15px;
    min-width: 750px;
    align-items: center;
    justify-content: center;
}

// Quick status card

.quick-status-card-paper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quick-status-inner-wraper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quick-status-icon-container {
    margin-left: 20px;
}

.status-inner-icon-wrapper {
    width: 50px;
    height: 50px;
    padding: 10px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

// Appbar Component

.app-bar-main {
    padding: 0 9%;
}

.app-bar-logo-image {
    width: 150px;
    height: auto;
}

.appbar-filler {
    flex-grow: 1;
}

.divider-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
