.account-verification-main-container {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #1e1e1e40;
}

.account-verification-status-wrapper {
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
}

// RESET PASSWORD
.reset-password-title-container {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #1e1e1e40;
}

.reset-password-form-wrapper {
    width: 100%;
    padding: 0 10px;
}

.reset-password-inner-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.reset-password-field-wrapepr {
    width: 35%;
}

.reset-password-submit-wrapper {
    width: 26%;
}

// SETTING

.settings-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings-inner-wrapper {
    min-width: 850px;
    max-width: 850px;
}

.settings-paper {
    padding: 2%;
}

.settings-paper-inner {
    overflow: auto;
    min-height: calc(100vh - 270px);
    max-height: calc(100vh - 270px);
}

.update-info-inpput-wrapper {
    width: 32%;
}

.update-info-btn-wrapper {
    width: 100%;
}
