.add-or-remove-title-container {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #1e1e1e40;
}

.add-remove-container {
    width: 100%;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
}

.add-remove-inner-wrapper {
    width: 50%;
}

.add-remove-field-wrapper {
    width: 100%;
}

.add-remove-feeds-wrapper {
    width: 48%;
}

.add-remove-progress {
    margin-right: 10px;
}

// Product add or remove

.admin-product-title {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #1e1e1e40;
}

.admin-product-main-container {
    width: 100%;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
}

.admin-product-field {
    width: 100% !important;
}

.admin-product-progress {
    margin-left: 10px;
}

.admin-product-view-wrapper {
    width: 48% !important;
}

.admin-product-view-paper {
    background-color: #3b3f47 !important;
}

.admin-product-inner-list-wrapper {
    width: 100% !important;
}

.admin-product-inner-wrapper {
    width: 50% !important;
    overflow: auto !important;
    // min-height: calc(100vh - 545px) !important;
    // max-height: calc(100vh - 545px) !important;
}

.admin-product-del-icon {
    color: #ff3838;
}

// Admin Panel

.admin-panel-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-panel-inner-wrapper {
    min-width: 850px;
    max-width: 850px;
}

.admin-panel-paper {
    padding: 2%;
}

.admin-panel-inner-container {
    overflow: auto;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
}

.admin-assign-license-title-container {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #1e1e1e40;
}

.admin-asign-inner-wrapper {
    width: 100%;
    padding: 0 10px;
}

.admin-assign-inner-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.admin-assign-filed-wrapper-1 {
    width: 30%;
}

.admin-assign-filed-wrapper-2 {
    width: 20%;
}

.admin-assign-filed-wrapper-3 {
    width: 15%;
}

.admin-assign-filed-wrapper-4 {
    width: 10%;
}

.admin-assign-btn-progress {
    margin-right: 10px;
}

// BLOCK UNBLOCK USER
.admin-user-title-wrapper {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #1e1e1e40;
}

.admin-user-form-wrapper {
    width: 100%;
    padding: 0 10px;
}

.admin-user-inner-form-con {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.admin-user-field-wrapper-1 {
    width: 60%;
}

.admin-user-field-wrapper-2 {
    width: 17%;
}

.admin-user-field-wrapper-3 {
    width: 20%;
}

// PERMENT USER REMOVAL

.admin-puser-main-contianer {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #1e1e1e40;
}

.admin-puser-formwrapper {
    width: 100%;
    padding: 0 10px;
}

.admin-puser-inner-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin-puser-field-con {
    width: 30%;
}

.admin-puser-confirm-con {
    width: 55%;
    display: flex;
    align-items: center;
}

.admin-puser-submitbtn-con {
    width: 15%;
}

// USER REPORT

.admin-userreport-container {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #1e1e1e40;
}

.admin-userreport-form-wrapper {
    width: 100%;
    padding: 0 10px;
}

.admin-userreport-form-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
