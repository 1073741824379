.main-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
    justify-content: center;
}

.input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input_fields_div {
    width: 49%;
}

.full-width {
    width: 100%;
}

.right {
    text-align: right;
}

.auth-signin-paper {
    padding: 20px;
    min-width: 350px;
    min-height: 200px;
}

.auth-signin-logo-img {
    width: 150px;
    height: auto;
}
