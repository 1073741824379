.license-board-wrapper {
    display: flex;
    align-items: center;
}

.loading-progress {
    margin-left: 10px;
}

.total-license {
    width: 85%;
    text-align: right;
}

.license-inner-wrapper {
    overflow-y: auto;
    min-height: calc(100vh - 315px);
    max-height: calc(100vh - 315px);
}

.expiration-days {
    color: #8a85ff;
}

.license-board-footer {
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
}

.sortby-wrapper {
    width: 30%;
}

// PRODUCT BOARD STYLES

.product-board-wrapper {
    display: flex;
    align-items: center;
}

.products-inner-wrapper {
    overflow-y: auto;
    min-height: calc(100vh - 255px);
    max-height: calc(100vh - 255px);
}

.product-board-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-board-inner-wrapper {
    min-width: 850px;
    max-width: 850px;
}

.products-wrapper {
    display: flex;
    justify-content: space-between;
}

.product-list-btn {
    padding: 10px 20px !important;
    justify-content: flex-start !important;
}
