.copy-right {
    position: absolute;
    left: 45%;
    bottom: 1%;
}

.dashboard-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-inner-wrapper {
    min-width: 850px;
    max-width: 850px;
}

.feedactivity-main-container {
    display: flex;
    min-height: 60%;
    max-height: 60%;
    align-items: center;
    justify-content: space-between;
}

.dashboard-feeds-wrapper {
    width: 33%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
}

.dashboard-feeds-inner-wrapper {
    display: flex;
    align-items: center;
}

.dashboard-activity-main-contaiener {
    width: 65%;
}

.dashboard-activity-inner-wrapper {
    display: flex;
    align-items: center;
}

.dashboard-activity-progress {
    margin-left: 10px;
}

// Quick card

.quickstatus-title-progress-con {
    display: flex;
    align-items: center;
}

.quick-card-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quick-card-wrapper {
    width: 24%;
}
